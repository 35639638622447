// need to override just one or two small stylistic things and don't want to edit the main .less files?  Add those overrides here

// COLOR PALLET
// --------------------------------------------------
@yellowLight: 					#FFFB81;
@yellowDark: 						#AFAC58;
@yellowBright:					#FAF604;


/* Reducir el alto de la imagen superior del profile */
.profile-carousel .carousel-inner {
    max-height: 100px;
}

/* Fondo rojo (para profile bloqueado, no procede solicitud) */
.well.well-redlight {
	background: lighten(@redLight, 33.5%);
	border: 1px solid darken(@redBright, 5%);
}

/* Fondo verde (procede solicitud) */
.well.well-greenlight {
	background: lighten(@greenLight, 33.5%);
	border: 1px solid darken(@greenBright, 5%);
}

/* Fondo amarillo (pendiente solicitud) */
.well.well-yellowlight {
	background: lighten(@yellowLight, 33.5%);
	border: 1px solid darken(@yellowBright, 5%);
}

