// Add anything custom here -- mixins, variable overrides.
// by adding things here instead of overriding existing .LESS files, it makes merging changes on product updates easier


// JEVS, Octubre 2016.
//
// Ajustes para usar logo de Bien para bien

/* Logo en Forma Login */
/* Disminuir el margen superior a 2 px */
#extr-page #header #logo1 {
    margin-top: 2px;
    margin-left: 0;
}

/* Disminuimos el ancho del logo mostrado */
#extr-page #header #logo1 img {
	width:110px;
}

/* Logo en Dashboard */
/* Disminuir el margen superior a 2 px*/
#logo2 {
  width:175px;
  margin-top: 2px;
  margin-left: 9px;
}

/* Reducir el logo para abarcar el header */
#logo2 img {
  width: 75px;
  height: auto;
  padding-left: 3px;
}
